import React from "react";
import styled from '@emotion/styled';

import SEO from "../components/seo"
import PageTitle from '../components/PageTitle';

const SuccessPage = () => (
  <>
    <SEO title="Success" />
    <CENTER>
      <PageTitle title="Success"/>
      <P>Congratulations, you've successfully signed up. Thank you for being one of the first to join The Insight Lifestyle! Tony will be emailing you details of what happens next within 24 hours. Keep an eye on your inbox. Insights, change, and getting more of the things you want are on their way.
      </P>

    </CENTER>
  </>
)

const CENTER = styled('main')`
  width: 100vw;
  margin: 0 auto;
  background: #FFF;

	// image switches to fullscreen
	@media (max-width: 500px) {
		width: 100%;
  }
`;

const P = styled('p')`
    font-size: 20px;
    width: 80%;
    text-align: center;
    padding-bottom: 80px;
    margin: 0 auto;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;

    @media (max-width: 500px) {
      padding-left: 50px;
      padding-right: 50px;
      font-size: 15px !important;
    }
`;

export default SuccessPage;